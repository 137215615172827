module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-delayed/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K5FQQ65","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"timeout":2000,"gtmAuth":"sbE_ZDhR4o67DeAJV4OXiA","gtmPreview":"env-1","dataLayerName":"DataLayer","routeChangeEventName":"routeChangeEventName","enableWebVitalsTracking":true},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"sv","prefixDefault":true,"configPath":"/home/vsts/work/1/s/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://logiplansewordpress.azurewebsites.net/graphql","schema":{"typePrefix":"Wp","perPage":50,"requestConcurrency":5,"previewRequestConcurrency":2,"timeout":180000,"queryDepth":15,"circularQueryLimit":5},"type":{"Form":{"beforeChangeNode":"./beforeChangeNodeForm"},"MediaItem":{"localFile":{"maxFileSizeBytes":268435456,"excludeByMimeTypes":[],"requestConcurrency":100},"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"develop":{"hardCacheMediaFiles":false,"nodeUpdateInterval":5000,"hardCacheData":false},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"verbose":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
